<template>
  <v-card class="overflow-hidden">
    <v-app-bar dense elevation="0" absolute fixed>
      <v-row align="center">
        <v-toolbar-title>Orders: {{ ordersTotal }} fetched</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-select v-model="searchFieldName" dense row class="shrink mr-2 mt-0"
                  label="Search in" solo :items="searchFields">
        </v-select>
        <v-text-field v-model="searchField" prepend-inner-icon="mdi-magnify" label="Search" single-line
                      hide-details dense
                      @keyup.enter.native=newSearch></v-text-field>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small left :loading="isLoading" @click="newSearch" v-bind="attrs" v-on="on">
              <v-icon>mdi-run</v-icon>
            </v-btn>
          </template>
          <span>Run a new search with given search criteria.</span>
        </v-tooltip>
      </v-row>
    </v-app-bar>
    <v-sheet class="overflow-y-auto" :height="orderAreaSizeY" v-resize="onResize">
      <v-container v-show="snackbar===true" dense no-gutters style="position: absolute; top:50px">
        <v-snackbar v-model="snackbar" timeout="8000" :absolute=true>
          {{ snackbarMsg }}
          <template v-slot:action="{ attrs }">
            <v-btn depressed small v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-container>
      <v-container id="order-area" class="overflow-y-auto" fluid style="position: relative; top:40px">
        <v-data-table  dense
            :headers="headers"
            :items="orders"
            :options.sync="options"
            :footer-props="{'items-per-page-options':[10, 15, 30, 60, 100, -1]}"
            :items-per-page="30"
            :server-items-length="ordersTotal"
            sort-by="created_at"
            :sort-desc="true"
            :loading="isLoading"
            :fixed-header="true"
            :height="tableHeight"
            class="elevation-1"
            :search="options.search"
        >
          <template v-slot:no-data>
            No orders
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="showItem(item)">mdi-eye</v-icon>
          </template>
        </v-data-table>
      </v-container>
    </v-sheet>
    <!--
    <v-bottom-navigation v-show="orders.length>0" height="60" style="background: transparent">
      <v-pagination v-model="pagination.page" class="my-4" :total-visible="15" :length="totalPages" circle
                    @input="onPageChanged"
      ></v-pagination>
    </v-bottom-navigation>
    -->
  </v-card>
</template>

<script>
export default {
  name: "OrderPage",
  components: {},
  data() {
    return {
      headers: [ // table headers
        {text: 'Channel', value: 'channel'},
        {text: 'Id', value: 'order_id'},
        {text: 'Year', value: 'year'},
        {text: 'Date', value: 'created_at'},
        {text: 'Customer Email', value: 'customer_id'},
        {text: 'Customer Name', value: 'customer_name'},
        {text: 'Country', value: 'country'},
        {text: 'Lines', value: 'no_line_items'},
        {text: 'Amount', value: 'total_amount'},
        {text: 'VAT', value: 'vat_region'},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
      searchField: "",
      options: {
        sortBy: ["created_at"],
        sortDesc: [true],
      },
      isLoading: false,
      orderFilter: {},
      orderAreaSizeY: 0, // the area y size for displaying orders
      tableHeight: 0, // table height
      snackbar: false,
      snackbarMsg: '',
      searchFields: [{text: 'Channel', value: 'channel'},
        {text: 'Year', value: 'year'},
        {text: 'Email', value: 'customer_id'},
        {text: 'Customer Name', value: 'customer_name'},
        {text: 'Country', value: 'country'},
        {text: 'VAT Region', value: 'vat_region'}],
      searchFieldName: '',
    }
  },
  computed: {
    orders() {
      return this.$store.getters['orderStore/getOrders']
    },
    ordersTotal() {
      return this.$store.getters['orderStore/getOrdersTotal']
    },
  },
  methods: {
    loadData() {
      this.isLoading = true;
      const {sortBy, sortDesc, page, itemsPerPage} = this.options
      let args = {}
      if (this.searchField != "" && this.searchFieldName != "") {
        args.filter = {
          field: this.searchFieldName,
          value: this.searchField,
        }
      }
      args.limit = itemsPerPage > 0 ? itemsPerPage : 0
      args.skip = (page - 1) * args.limit
      if (sortBy) {
        args.sort = sortBy[0]
        args.sort_desc = sortDesc[0]
      }
      return this.$store.dispatch('orderStore/fetchOrders', args).then(() => {
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    }
    ,
    newSearch() {
      console.log("Search field=" + this.searchFieldName + " search for " + this.searchField)
      this.options.page = 1
      this.options.search = this.searchField
      this.loadData()
    }
    ,
    onPageChanged() {
      //this.loadData()
    }
    ,
    onResize() {
      this.orderAreaSizeY = window.innerHeight - 110
      this.tableHeight = window.innerHeight - 240
    }
    ,
    showItem(item) {
      let index = this.orders.indexOf(item)
      alert(this.orders[index]["customer_id"] + " | order-id=" + this.orders[index]["id"])
    }
    ,
  }
  ,
  created() {
  }
  ,
  watch: {
    options: {
      handler() {
        this.loadData()
      }
      ,
      deep: true,
    }
    ,
  }
  ,
  mounted() {
    this.onResize()
    this.page = 1
    this.$store.dispatch('orderStore/clearOrders')
  }
  ,

}
</script>

<style scoped>

</style>